<template>
  <div class="user-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "房东信息",
        form: [
          {
            label: "姓名",
            key: "userName",
            rules: [{ required: true }]
          },
          {
            label: "性别", 
            key: "gender",
            type: 'select',
            opt: {
              list: [
                {
                  value: '1',
                  label: "男"
                },
                {
                  value: '2',
                  label: "女"
                }
              ]
            },
            disabled: true,
            // rules: [{ required: true }]
          },
          // {
          //   label: "民族",
          //   key: "nation",
          //   disabled: true,
          //   // rules: [{ required: true }]
          // },
          {
            label: "出生日期",
            key: "birthday",
            // rules: [{ required: true }]
          },
          {
            label: "住址（身份证）",
            key: "idcardAddress",
            // rules: [{ required: true }]
          },
          {
            label: "身份证号",
            key: "idCardNo",
            rules: [{ required: true }]
          },
          {
            label: "电话号码",
            key: "phoneNum",
            type: "number",
            rules: [{ required: true }]
          },
          {
            label: "现居住地",
            key: "address",
            rules: [{ required: false }]
          },
          {
            label: "紧急联系人电话",
            key: "emergencyPhoneNum"
          },
          // {
          //   label: "角色",
          //   key: "usertypeStr",
          //   readonly: true
          // },
          {
            label: "房源",
            key: "houseNum",
            disabled: true
          },
          {
            label: "添加人",
            key: "createdBy",
            disabled: true 
          },
          {
            label: "账号状态", 
            key: "userState",
            type: "switch",
            rules: [{ required: true }]
          }
          // {
          //   label: "备注信息",
          //   key: "remarks",
          //   type: "textarea"
          // }
        ],
        buttons: [
          {
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              let dto = {
                username: form.userName,
                birthday: form.birthday,
                idcardAddress: form.idcardAddress,
                idCardNo: form.idCardNo,
                phonenum: form.phoneNum,
                address: form.address,
                emergencyPhoneNum: form.emergencyPhoneNum,
                houseNum: form.houseNum.toString(),
                createdBy: form.createdBy,
                userstate: form.userState ? "2" : "1",
              }; 
              let url = "user-service/apartmentuser/addApartmentuser";
              let message = "添加栏目成功";
              if (form.userId) {
                url = "user-service/apartmentuser/updateApartmentuser";
                delete dto.houseNum;
                delete dto.createdBy;
                dto.userid = form.userId;
                message = "编辑栏目成功";
              }
              _this.post(url, dto).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                 _this.$router.push("/main/landlord/list");
              });
            }
          }
        ],
        get(opt) {
          let dto = {
            userid: opt.id
          };
          _this.post("user-service/apartmentuser/queryApartmentuser", dto).then(data => {
            data.usertypeStr = ['主租户', '合租者'][data.usertype - 1];
            data.userState = data.userState == 2 ? true : false;
            data.gender = ['男', '女'][data.gender - 1];
            opt.cb(data); 
          }); 
        }
      }
    };
  },
  created: function() {
    console.log("user-detail created!!");
  },
  methods: {

  }
};
</script>

